import React from 'react';

import {Container, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

export default function Experience() {

    return (
        <div id="experience" className="pad">
            <style>
                {`
                   .languer{
                   height: 100px;
                   }
                        .btn-xxl {
                          padding: 1rem 1.5rem;
                          font-size: 1.5rem;
                        }
                        `}
            </style>
            <Container >
                <Row>
                    <Col> <h3>Work Experience</h3></Col>
                </Row>
                <Row>
                    <Timeline position="alternate">
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="body2" >
                                    02/2021
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot>
                                    <LaptopMacIcon />
                                </TimelineDot>
                                <TimelineConnector className="languer" />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper style={{backgroundColor:"#F8F9FA",padding:'15px'}} elevation={3} >
                                    <Typography variant="h6" component="h1">
                                        Intelcia IT Solution
                                    </Typography>
                                    <Typography>PHP Developer</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="body2" >
                                    09/2020 - 01/2021
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot>
                                    <LaptopMacIcon />
                                </TimelineDot>
                                <TimelineConnector className="languer" />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper style={{backgroundColor:"#F8F9FA",padding:'15px'}} elevation={3} >
                                    <Typography variant="h6" component="h1">
                                        SAGlobal
                                    </Typography>
                                    <Typography>Developer CRM Dynamics 365</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="body2" >
                                    03/2020 - 08/2020
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot>
                                    <LaptopMacIcon />
                                </TimelineDot>
                                <TimelineConnector className="languer" />
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper style={{backgroundColor:"#F8F9FA",padding:'15px'}} elevation={3} >
                                    <Typography variant="h6" component="h1">
                                        SAGlobal
                                    </Typography>
                                    <Typography>Internship - Development the Transport Management System under Dynamics 365</Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                            <TimelineOppositeContent>
                                <Typography variant="body2" >
                                    07/2020 - 09/2020
                                </Typography>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot>
                                    <LaptopMacIcon />
                                </TimelineDot>
                            </TimelineSeparator>
                            <TimelineContent>
                                <Paper style={{backgroundColor:"#F8F9FA",padding:'15px'}} elevation={3} >
                                    <Typography variant="h6" component="h1">
                                        Pexiluis Africa
                                    </Typography>
                                    <Typography>
                                        Internship - Development of a decision information system for Pexiluis Africa management.
                                    </Typography>
                                </Paper>
                            </TimelineContent>
                        </TimelineItem>
                    </Timeline>
                </Row></Container>
        </div>
    );
}
