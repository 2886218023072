import * as React from "react";
import {Container, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import './style.css';
import '../../themes/css/timeLine.css'
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import Timeline from "@mui/lab/Timeline";
import {timelineItemClasses} from "@mui/lab";
import SchoolIcon from '@mui/icons-material/School';

class Education extends React.Component {
    render() {
        return (
            <div id="education" >

                <Container >
                    <Row>
                        <Col> <h3>Education</h3></Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={6}>
                            <Timeline
                                sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                        flex: 0,
                                        padding: 0,
                                    },
                                }}>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary">
                                            <SchoolIcon fontSize={"small"}/>
                                        </TimelineDot>
                                        <TimelineConnector className="languer" />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <h5 className="fw-bold">Self-taught</h5>
                                        <p className="text-muted mb-2 fw-bold">2014 - present</p>
                                        <p className="text-flow">
                                            I have a strong foundation in programming concepts and a deep passion for learning
                                            new technologie. Throught online ressources , tutorials and practical experiences.<br/>
                                            I have developed experience in Web , mobile developement. I have worked in variety of personnel or profetionnal project
                                        </p>
                                    </TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </Col>
                        <Col lg={6} md={6}>
                            <Timeline
                                sx={{
                                    [`& .${timelineItemClasses.root}:before`]: {
                                        flex: 0,
                                        padding: 0,
                                    },
                                }}>
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot color="primary">
                                            <SchoolIcon fontSize={"small"} />
                                        </TimelineDot>
                                        <TimelineConnector className="languer" />
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <h5 className="fw-bold">EMSI</h5>
                                        <p className="text-muted mb-2 fw-bold">2018 - 2020</p>
                                        <p className="text-flow">
                                            The Moroccan School of Engineering Sciences, EMSI, is a higher education institution founded in 19861 by a group of university
                                            teachers to train students in the fields of engineering, new technologies and telecommunications. in Morocco.
                                        </p>
                                    </TimelineContent>
                                </TimelineItem>
                            </Timeline>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default Education;
