import * as React from "react";
import {Container, Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import CardProject from "../Component/CordProject";
import {projects} from "../../data/Projects";
import './style.css'

class Project extends React.Component {
    render() {
        return (
            <div id="project" >
                <div  className="project blocs">
                <Container >
                    <Row>
                        <Col> <h3>Projects</h3></Col>
                    </Row>
                    <Row>
                        {projects.map((project, index) => (
                            <Col lg={3} md={6}>
                                <CardProject project={project} />
                            </Col>
                        ))}
                    </Row>
                </Container>
                </div>
            </div>
        );
    }
}
export default Project;
