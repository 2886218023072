import React from 'react';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container);
const theme = createTheme({
    typography: {
        fontFamily: 'Sriracha, sans-serif',
    },
});

root.render(<React.StrictMode>
    <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>
</React.StrictMode>);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();