import web from '../Image/web_development.svg';
import mobile from '../Image/mobile_development.svg';
import db from '../Image/server.svg';
import program from '../Image/proud_coder.svg';
import design from '../Image/design.svg';
import erp from "../Image/ERP.svg";
export const skillsData = [
    {
        category: "Web Development",
        technologies: ["Spring", "Bootstrap", "React", "Angular", "Prado"],
        imageSrc: web
    },
    {
        category: "Mobile Development",
        technologies: ["Android", "Ionic", "React", "Flutter"],
        imageSrc: mobile
    },
    {
        category: "Database",
        technologies: ["Oracle", "SQL", "SQLite", "MySQL", "SQL Server"],
        imageSrc: db
    },
    {
        category: "Programming Language",
        technologies: ["C", "C++", "C#", "Java", "JavaScript", "Python", "Dart", "PHP"],
        imageSrc: program
    },
    {
        imageSrc: design,
        category: 'Design',
        technologies: ["Adobe Xd", "Scene Builder"],
    },
    {
        category: 'ERP / CRM',
        imageSrc: erp,
        technologies: ["CRM Dynamics 365", "Odoo"]
    }
];
