import * as React from "react";
import {Container, Row, Col} from "react-bootstrap";
import logoWhite from '../../Image/SS.png';
import logoDark from '../../Image/SSWhite.png';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import './style.css';

class Contact extends React.Component {
    render() {
        const logo = (this.props.theme==='light')?logoWhite:logoDark;
        return (
            <div id="contact" className="blocs">
                <div className="contact">
                    <Container>
                        <Row>
                            <Col lg={4}>
                                <div>
                                    <h6><img  alt="sennouni sara" className="image" src={logo} width={50} />SENNOUNI SARA</h6>
                                </div>
                                <div>
                                    <p></p>
                                </div>
                                <div>
                                    <a href="#profile">Profile</a> - <a href="#education">Education</a> - <a href="#experience">Experience</a> - <a href="#skills">Skills</a>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <LocationOnOutlinedIcon color={"primary"}/> Boulevard Laymoune - Morocco, Casablanca<br/>
                                <PhoneIphoneOutlinedIcon color={"primary"}/> +212 698-662949<br/>
                                <ContactMailOutlinedIcon color={"primary"}/> sarasennouni@outlook.com
                            </Col>
                            <Col lg={4}>
                                <h6>About me</h6>
                                <p>Software developer</p>
                                <br/>
                                <div>
                                    <a href="https://drive.google.com/file/d/1EezWAinaokrZhOF-yMitb2chqUBJay6M/view?usp=share_link">
                                        <CodeOutlinedIcon color={"primary"} fontSize={"large"} />
                                    </a>
                                    <a href="https://github.com/sennounisara">
                                        <GitHubIcon color={"primary"} fontSize={"large"} />
                                    </a>
                                    <a href="https://drive.google.com/file/d/1EezWAinaokrZhOF-yMitb2chqUBJay6M/view?usp=share_link">
                                        <FacebookIcon color={"primary"} fontSize={"large"}/>
                                    </a>
                                    <a href="https://www.linkedin.com/in/sara-sennouni-0722b7135/">
                                        <LinkedInIcon color={"primary"} fontSize={"large"}/>
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <div >Copyright © 2019–2024. All rights reserved</div>
                </div>
            </div>
        );
    }
}
export default Contact;
