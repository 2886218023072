import dynamics from '../Image/Dynamics.PNG';
import geofit from '../Image/geofit.png'
import pfe from '../Image/pfe.png';
import smartfocus from '../Image/smartfocus.PNG';

export const projects = [
    {
        title: "Development of a decision information system for Pexiluis Africa management.",
        technology: "Desktop: Electron / Web: Angular, Spring / Database: MySQL",
        imgSrc: geofit,
    },
    {
        title: "Development of a Transport Management System using Dynamics 365",
        technology: "Dynamics CRM 365",
        imgSrc: dynamics,
    },
    {
        title: "University project management",
        technology: "Php, Bootstrap, MySQL",
        imgSrc: pfe,
    },
    {
        title: "Smart Focus",
        technology: "Web: ReactJs / Mobile: Flutter",
        imgSrc: smartfocus,
    },
];
